import React, {useState} from 'react';
import style from "../../styles/Faq.module.scss";

interface AccordionProps {
    title: string,
    content: string,

}


const Accordion = ({title, content}: AccordionProps) => {

    const [isActive, setIsActive] = useState(false);

    const showTab = () =>{
        setIsActive(!isActive);
    }


    return (
        <div className={style.accordion_item}>
            <div className={style.accordion_title} onClick={showTab}>
                <div>{title}</div>
                <div style={{cursor: "pointer"}}>{isActive ? '-' : '+'}</div>
            </div>
            <div className={isActive ? style.accordion_content : style.accordion_content_hide}>{content}</div>
        </div>
    );
};

export default Accordion;