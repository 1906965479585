import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import style from '../styles/ContactUs.module.scss';
import Navbar from "../components/Navbar/Navbar";
import { useFormik } from 'formik';
import FormInput, { FormTextarea } from "../components/Inputs/FormInput";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import Footer from "../components/Footer/Footer";
import { useLocation } from 'react-router-dom';

const From = () => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            email: '',
            message: '',
        },
        onSubmit: () => {
        },
    });

    function sendEmail(e:any){
        e.preventDefault()
        
        setLoading(true)
        emailjs.sendForm('service_c8zhxec', 'template_r8dlqgj', e.target, 'pbQ0YpiKjP0LZ3Zg9')
        .then(() => {
            alert("Your message has been successfully sent")
            setLoading(false)
        })
        .catch(() => alert("Email not sent! please try again."))
    }


    return (
        <form onSubmit={sendEmail} className={style.form}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
            }}>
                <FormInput
                    id="firstName"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    label={"First Name"}
                    htmlFor={"firstName"}
                />
                <FormInput
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    label={"Last Name"}
                    htmlFor={"lastName"}
                />
            </div>
            <FormInput
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                label={"Email Address"}
                htmlFor={"email"}
            />
            <FormTextarea id={"textarea"}
                name={"message"}
                type={"textarea"}
                label={"Message"}
                onChange={formik.handleChange}
                value={formik.values.message}
                htmlFor={"textarea"}
            />
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: 'center',
                marginTop: "20px"
            }}>
                <button type="submit"><PrimaryButton disabled={loading || !formik.values.message && !formik.values.email && !formik.values.name && !formik.values.lastName} > {loading ? "SENDING..." : "SEND"} </PrimaryButton></button>
            </div>
        </form>
    )
        ;
}

const ContactUs = () => {
    return (
        <>
            <div className={style.contactUs__wrapper}>
                <Navbar />
                <div className={style.contactUs__form}>

                    <h1><span style={{ color: '#910420' }}>CONTACT</span> US</h1>
                    <From />
                </div>
                <a className={style.backHome} href="/">
                    <PrimaryButton>BACK TO HOME</PrimaryButton>
                </a>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;