import React from 'react';
import style from './Buttons.module.scss';


interface PrimaryButtonProps {
    children: any,
    backgroundColor?: string,
    isInputButton?: boolean,
    type?: any
    onClick?: any,
    disabled ?: boolean
}


const PrimaryButton = ({ children, backgroundColor, isInputButton, type, onClick, disabled }: PrimaryButtonProps) => {

    let className = style.primaryButton;

    if (isInputButton) {
        className = style.primaryButton__input
    }
    if (backgroundColor === "white") {
        className = style.whiteButton
    }

    return (
        <button className={className}
                type={type}
                onClick={onClick}
                disabled={disabled}
        >
            {children}
        </button>
    );
};

export default PrimaryButton;