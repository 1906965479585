import React from 'react';
import style from "./Member.module.scss";
import fb from "../../images/social-icons/fb.svg";
import ig from "../../images/social-icons/ig.svg";
import li from "../../images/social-icons/li.svg";
import tw from "../../images/social-icons/tw.svg";
import {links} from '../../consts/links';

interface ServicesItemProps {
    name?: string,
    role?: string,
    text?: string,
    icon?: string,
    social_links?: [],
    classSt?: any,
    firstImage?: any,
    rotateImg?: any,
}


const MemberCard = ({name, role, text, icon, classSt, firstImage, rotateImg}: ServicesItemProps) => {
    return (
        <div className={style.member_card}>
            <img className={style.member_card__icon} src={icon} alt="member_icon"/>
            <h3>{name}</h3>
            <h4>{role}</h4>
            <p>{text}</p>
            <div className={style.link_block}>
                <div className={style.link_block__link_item}>
                    <a target="_blank" href='#'>
                        <img src={fb} alt="fb"/>
                    </a>
                </div>

                <div className={style.link_block__link_item}>
                    <a target="https://twitter.com/dionysuspool?s=20" href={links.twitter}>
                        <img src={tw} alt="tw"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MemberCard;