import React from 'react';
import style from "../styles/Faq.module.scss";
import Navbar from "../components/Navbar/Navbar";
import Accordion from '../components/Accordion/Accordion';
import Footer from "../components/Footer/Footer";
import PrimaryButton from "../components/Buttons/PrimaryButton";


const accordionData = [
    {
        title: 'WHAT IS CARDANO AND HOW DOES IT DIFFER FROM OTHER BLOCKCHAIN PLATFORMS?',
        content: `Cardano is a decentralized blockchain platform that uses proof-of-stake consensus. It differs from other platforms in its scientific approach to development, focus on sustainability, and multi-layer architecture.`
    },
    {
        title: 'HOW DO I BUY AND STORE ADA?',
        content: `ADA can be purchased on various cryptocurrency exchanges, and can be stored in a Cardano-compatible wallet, such as Daedalus or Yoroi`
    },
    {
        title: 'HOW DO I CREATE A WALLET TO STORE MY ADA?',
        content: `You can create a Cardano wallet using a software wallet like Daedalus or Yoroi, or a hardware wallet like Ledger or Trezor`
    },
    {
        title: 'WHAT ARE THE BENEFITS OF STAKING ADA?',
        content: `Staking ADA allows you to earn rewards for helping to secure the network, while also participating in the platform's governance system.`
    },
    {
        title: 'WHAT IS A DAPP ON THE CARDANO BLOCKCHAIN, AND HOW CAN I USE THEM?',
        content: `A DApp is a decentralized application built on the Cardano blockchain. You can use them for a variety of purposes, such as financial transactions, voting systems, supply chain management, and gaming.`
    },
    {
        title: 'WHAT IS A SMART CONTRACT ON CARDANO, AND HOW DO THEY WORK?',
        content: `A smart contract on Cardano is a self-executing digital contract that is programmed to enforce the terms of an agreement between parties. They are written in the Plutus programming language and executed on the blockchain.`
    },
    {
        title: 'HOW DOES THE CARDANO GOVERNANCE SYSTEM WORK, AND HOW CAN I PARTICIPATE IN IT?',
        content: `The Cardano governance system is based on a stakeholder voting system, where stakeholders can vote on proposals and changes to the network. You can participate by staking ADA and voting on proposals.`
    },
    {
        title: 'HOW CAN I CONTRIBUTE TO THE CARDANO COMMUNITY AND ECOSYSTEM?',
        content: `You can contribute to the Cardano community by participating in discussions, contributing to open-source projects, or developing your own DApps or tools on the platform.`
    },
    {
        title: 'WHAT ARE THE SECURITY FEATURES OF CARDANO, AND HOW IS MY DATA PROTECTED ON THE BLOCKCHAIN?',
        content: `Cardano uses various security features, such as multi-signature wallets, deterministic wallets, and audit trails to protect user data and prevent fraud.`
    }
];


const Faq = () => {
    return (
        <>
            <div className={style.faq__wrapper}>
                <Navbar/>
                <div className={style.faq__content}>
                    <h1><span style={{color: "#910420"}}>FREQUENT</span> ASKED QUESTIONS</h1>
                    <div className={style.accordion}>
                        {accordionData.map(({title, content}) => (
                            <Accordion title={title} content={content}/>
                        ))}
                    </div>
                </div>
                <a href="/" style={{
                    width: "100%",
                    marginTop: "100px",
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <PrimaryButton>BACK TO HOME</PrimaryButton>
                </a>
            </div>
            <Footer/>
        </>
    );
};

export default Faq;
