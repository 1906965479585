import React, {useState} from 'react';
import style from './Navbar.module.scss';
import '../../App.css';
import PrimaryButton from "../Buttons/PrimaryButton";
import logo from "../../images/logo.svg";
import {navbarConsts} from "../../consts/navbar-consts";
import open_button from "./open_button.svg";
import close_button from "./close.svg";
import logo_mobile_menu from "./logo_mobile_menu.svg";
import wine from '../../images/img.png';
import {Link} from "react-router-dom";


interface Props {
    servicesRef?: any,
    whitePaperRef?: any,
    refHome?: any,
    refProject?: any,
    refTeam?:any,
    refPartners?: any,

}


const Navbar = ({servicesRef, whitePaperRef, refHome, refProject, refTeam, refPartners}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openMenu = () => {
        setIsOpen(!isOpen);
    }

    const valuesScroll: any = () => servicesRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    const whitePapperScroll: any = () => whitePaperRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    const homeScroll: any = () => refHome?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    const projectScroll: any = () => refProject?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    const teamScroll: any = () => refTeam?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    const PartnersScroll: any = () => refPartners?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});


    return (
        <>
            {isOpen ? <div className="showColor"/> : null}
            <nav className={style.navbar}>
                <div>
                    <a href="/"> <img src={logo} alt="logo_nav"/></a>
                </div>
                <ul className={style.navbar__link_section}>
                    <li onClick={homeScroll}>
                        <a href="/">HOME</a>
                    </li>
                    <li onClick={valuesScroll}>
                        <a>SERVICE</a>
                    </li>
                    <li onClick={projectScroll}>
                        <a>PROJECTS</a>
                    </li>
                    <li onClick={teamScroll}>
                        <a>TEAM</a>
                    </li>
                    <li onClick={PartnersScroll}>
                        <a>PARTNERS</a>
                    </li>
                    <li onClick={whitePapperScroll} style={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: "center"
                    }}>
                        <a>WINEPAPER</a>
                        <img className={style.showWine} src={wine} alt="img"></img>
                    </li>
                  {/*  {navbarConsts.map((item, index) =>
                        index === 5
                            ? (<li key={index} style={{
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: "center"
                            }}>
                                <a href={item.link}>{item.text.toUpperCase()}</a>
                                <img className={style.showWine} src={wine} alt="img"/>
                            </li>)
                            : (<li key={index}>
                                <a href={item.link}>{item.text.toUpperCase()}</a>

                            </li>)
                    )}*/}
                    <li>
                    </li>
                </ul>
            </nav>


            <nav className={style.navbar__mobile_version}>
                <a href="/"> <img src={logo} alt="logo_nav"/></a>
                <img className={style.image_burger} onClick={openMenu} src={open_button} alt="icon_open"/>
            </nav>


            <div className={style.nav_link_part} style={{
                transition: '.5s ease',
                opacity: isOpen ? 1 : 0,
                transitionDuration: '1s',
                transform: isOpen ? "translateX(0vw)" : "translateX(70vw)",
            }}>
                <img style={{
                    position: "absolute",
                    right: "20px"
                }} onClick={openMenu} src={close_button} width={32} height={32} alt="icon_open"/>
                <div className={style.content_block}>
                    <img width={93} height={130} src={logo_mobile_menu} alt="logo"/>
                    <ul>
                        <li onClick={openMenu}>
                            <Link to={'/'} onClick={homeScroll}>HOME</Link>
                        </li>
                        <li onClick={openMenu}>
                            <Link to={"/"} onClick={valuesScroll}>OUR SERVICE</Link>
                        </li>
                        <li onClick={openMenu}>
                            <Link to={"/"} onClick={teamScroll}>TEAM</Link>
                        </li>
                        <li onClick={openMenu}>
                            <Link to={"/"} onClick={whitePapperScroll}>WINEPAPER</Link>
                        </li>
                        <li onClick={openMenu}><a href="/contacts">CONTACT</a></li>
                    </ul>
                    <div style={{
                        marginTop: "50px"
                    }}>

                    </div>
                </div>
            </div>
        </>
    )
        ;
};

export default Navbar;
