import React, { useRef } from 'react';
import Navbar from "../components/Navbar/Navbar";
import style from "../styles/MainPage.module.scss";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import ServiceItem from "../components/Service/ServiceItem";
import dappsIcon from '../images/services/dapps-icon.svg';
import cardanoIcon from '../images/services/cardano.svg';
import coins from '../images/services/coins.svg';
import Projects from "../components/Projects/Projects";
import MemberCard from "../components/TeamCard/MemberCard";
import miroIcon from "../images/team/miro_round.png";
import Igor from "../images/team/igor_round.png";
import Matej from "../images/team/matej_round.png";
import vps from '../images/partners-icon/vps.svg';
import cardano from '../images/partners-icon/cardano.svg';
// import ikea from '../images/partners-icon/ikea.svg';
// import mers from '../images/partners-icon/mers.svg';
// import nasa from '../images/partners-icon/nasa.svg';
// import apple from '../images/partners-icon/apple.svg';
import wine from '../images/img.png';
import winepapperBack from '../images/white-icon.png';
import Footer from "../components/Footer/Footer";
import { useOnScreen } from '../hooks/UseOnScreen';
// @ts-ignore
import whitepaper from '../assets/whitepaper.pdf'


const services_item_data = [
    {
        icon: dappsIcon,
        title: 'DAPPS',
        text: 'We are committed to build new dApps for all members of the Cardano eco-system. ADA holders have chance to interact, transact, plan, participate and profit from using our dApps. Although we have 2 main projects in the pipeline, we are in the early stage of making the use of decentralised service and smart contract available for the short range of use-cases and professions.'
    },
    {
        icon: cardanoIcon,
        title: 'POOL OPERATIONS',
        text: 'Our bare-metal infrastructure and corporate grade security delivers important piece to the set of decentralised and secure blockchain operations. Stake in our pool is transparent: no locks of assets, no vesting, no 3rd grade tokens with vanishing value by the time.'
    },
    {
        icon: coins,
        title: 'STAKING BENEFITS',
        text: 'DIO delegators are served with discounts. Their staking handle and loyalty tokens give access to special dApps features, NFT drops and always guaranteed direct access to our product team. Dionysus story starts with staking and develops to the value that our delegators receive. After finalising the stage of Managed Rewards Distribution, DIO pool serves as a connector between ADA holders of similar interest in building their portfolio of native assets, stable coins and NFTs. We are here for you for every step in your journey.'
    },
]

const project_mock_data = [
    {
        title: <h1><span style={{ color: '#910420' }}>STAKING</span> POOL</h1>,
        text: <p>Staking pool is the core Dionysus service provided to the cardano community. Server is running on a
            bare metal infrastructure with virtualized resources. Dionysus pool operates as a transactions validator +
            blocks producer. Nodes and Block Producer are redundant, covered behind customized multi-layered security
            stack. Stake pool is located on a secured place in Switzerland, with access to fiber-speed internet, UPCs
            and switch-ready restore if needed.</p>,
        button_text: 'STAKE NOW',
        button_link: 'https://pool.pm/3d8103dd2ad0838d985a3fc00277c55da15279088f3b1dc11dd814ce'
    },
    {
        title: <h1><span style={{ color: '#910420' }}>KALY</span>X</h1>,
        text: <p>Kalyx is a solution to LONG / SHORT clients position against crypto assets and native tokens. 
            We call our future contracts Decentralized Contracts for Future Difference, dCFDs. 
            Contracts are over-collateralised, winner takes all the TVL. User version has been developed as 
            first MVP in dCFD eco-system and is available on</p>,
        button_text: 'Enter the App',
        button_link: 'https://kalyx.io'
    },
    {
        title: <h1><span style={{ color: '#910420' }}>COMING</span> SOON</h1>,
        text: <p> Future implementations are directed towards DEXes and Wallet Providers with ability to integrate dCFD features.
            <span style={{ color: '#910420' }}>subscribe to our newsletter</span> and you will be notified
            when we launch our new projects</p>,
        button_text: 'SUBSCRIBE',
        button_link: '#connect_section'
    },
]

const text_item_data = [
    {
        icon: miroIcon,
        role: 'Partner',
        name: 'Miro',
        text: 'Miro has been the brains behind the Dionysus operation in  areas of business, marketing and case modelling. He likes to design Web3 applications "above & beyond" and pledges to deliver the best experience to the Cardano community and to casual users. His market perception has been one of the integral competences in blockchain business integrations with the legacy world.',
    },
    {
        icon: Igor,
        role: 'Partner',
        name: 'Igor',
        text: 'Igor is the technical mastermind of the Dionysus ecosystem. With his expertise, he takes care of infrastructure, development, security and operations. His affinity for computer science lays another foundation for the future of Dionysus and, by extension, the future of Cardano. Igor is a natural facilitator of opportunities. He brings new ideas, people and implementation approaches to the table.'
    },
    {
        icon: Matej,
        role: 'UX/UI Designer',
        name: 'Matej',
        text: 'Matej is the fresh spark of the team Dionysus. Every brush stroke, colour mix, animation and web element, as well as visual workflow transitions, rise from the ink of his pen. Team is boosted by his significant chip-in and continues to build on his great experience form the competitive gaming, graphical design and hazard business campaigns on social media.',
    },
]

const partners_icon = [
    { icon: cardano, link: 'https://cardano.org/'  },
    { icon: vps, link: 'https://vipstaple.com/' },
    // { icon: opshin, link: 'https://developers.cardano.org/docs/smart-contracts/opshin/' },
    //    {icon: ikea},
    //    {icon: mers},
    //    {icon: nasa},
    //    {icon: apple},
]


const MainPage = () => {


    const refS: any = useRef();
    const refP: any = useRef();
    const refT: any = useRef();
    const refW: any = useRef();
    const refHome: any = useRef();
    const refPartners: any = useRef();

    const onScreenS = useOnScreen(refS);

    // download white paper
    const handleDownload = () => {
        fetch(whitepaper)
            .then(response => {
                // Create a blob from the response data
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'whitepaper.pdf';
                a.click();
            });
    };


    return (
        <div className={style.mainSection__wrapper} ref={refHome}>
            <Navbar servicesRef={refS} whitePaperRef={refW} refHome={refHome} refProject={refP} refTeam={refT} refPartners={refPartners} />
            <div className={style.mainSection}>
                <h1>
                    <span style={{ color: "#910420" }}>DIONYSUS</span>
                    <br />
                    dApps & Pool
                    <br />
                    on Cardano
                </h1>
                <p>
                    Dionysus pool has been operational since December 20th, 2021. <br />
                    We build dApps for the community and value our delegators for being with us.
                </p>
                <a href="https://pool.pm/3d8103dd2ad0838d985a3fc00277c55da15279088f3b1dc11dd814ce" >
                    <PrimaryButton>STAKE NOW</PrimaryButton>
                </a>
                <br />
                <br />
                <br />
                <p>
                    Dionysus uses opshin as upper layer for crafting and managing smart contracts. <br />
                    We are committed to continue the development of this programming language for the Cardano Community
                </p>
                 <a href="https://developers.cardano.org/docs/smart-contracts/opshin/" >
                    <PrimaryButton>OPSHIN</PrimaryButton>
                </a>
            </div>
            <div id={'services_section'} className={style.servicesSection}>
                <div className={style.servicesSection__header}>
                    <h1
                        ref={refS}
                        style={{
                            transition: "2s ease",
                            opacity: onScreenS ? 1 : 0
                        }}
                    >Our <span style={{ color: "#910420" }}>Service</span></h1>
                    <p className={style.header_description} ref={refS}
                        style={{
                            transition: "2s ease",
                            opacity: onScreenS ? 1 : 0
                        }}>
                        Dionysus pool is the place where Block Production meets with value for Cardano participants
                        from
                        the non-custodian, safe and transparent dApps services.
                    </p>
                </div>
                <div className={style.servicesSection__itemBlock}>
                    {services_item_data.map((item, index) => (
                        <ServiceItem compareBlock={index === 2 ? "compares" : null} text={item.text} title={item.title}
                            icon={item.icon} key={index} />
                    ))}
                </div>
            </div>
            <div style={{
                backgroundColor: '#1D1D1D',
                display: "flex",
                overflow: 'hidden'
            }}>
                <div className={style.bottom_wave} />
            </div>
            <div className={style.otherSection_wrapper}>
                <div id={"project_section"} className={style.projectServices}>
                    <div className={style.projectServices__header}>
                        <h1
                            ref={refP}
                            style={{
                                transition: "2s ease",
                                opacity: onScreenS ? 0 : 1
                            }}
                        >Projects</h1>
                        <p ref={refP}
                            style={{
                                transition: "2s ease",
                                opacity: onScreenS ? 0 : 1
                            }} className={style.header_description}>
                            <span style={{ color: '#910420' }}>Dionysus pool</span> is still in development phase and
                            there are more
                            things coming to our platform.
                        </p>
                    </div>
                    <div className={style.projectServices__project_services}>
                        {project_mock_data.map((item, index) => (
                            <div key={index} className={style.test}>
                                <Projects title={item.title} text={item.text} button_text={item.button_text}
                                    link_button={item?.button_link} />
                            </div>
                        ))}
                    </div>

                </div>

                <div id={"team_section"} className={style.team_section}>
                    <div className={style.team_section__header}>
                        <h1 ref={refT}
                            style={{
                                transition: "2s ease",
                                opacity: onScreenS ? 0 : 1
                            }}>TEAM</h1>
                        <p ref={refT}
                            style={{
                                transition: "2s ease",
                                opacity: onScreenS ? 0 : 1
                            }} className={style.header_description}>
                            Our Team delivers immersive experience from dApps and honours the validator services
                            with
                            stake pool <a style={{
                                textDecoration: "underline #910420"
                            }} href={"https://pool.pm/3d8103dd2ad0838d985a3fc00277c55da15279088f3b1dc11dd814ce"}
                                target="_blank">
                                <span style={{ color: '#910420' }}>DIO</span>
                            </a>. Participants in Cardano
                            ecosystem,
                            as well as our dear delegators are the
                            first priority in every action from our team.
                        </p>
                    </div>
                    <div className={style.team_section__team_block}>
                        {text_item_data.map((item, index) => (
                            <div key={index}>
                                <MemberCard rotateImg={index === 0 ? "rotate" : "none"}
                                    firstImage={index === 0 ? "first_image" : "none"}
                                    classSt={index === 2 ? "margin" : "none"} name={item.name} role={item.role}
                                    text={item.text} icon={item.icon} />
                            </div>
                        ))}
                    </div>
                    <div id={"partners_section"} className={style.partners_block}>
                        <div ref={refPartners} className={style.partners_block__header}>
                            <h1>PARTNERS</h1>
                        </div>
                        <div className={style.partners_icons}>
                            {partners_icon.map((icon, index) => (
                                <div key={index} className={style.partners_icons__icon}>
                                    <a href={icon.link} target="_blank" rel="noopener noreferrer">
                                        <img src={icon.icon} alt="icon_partner" />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '200px'
                    }} ref={refW}>
                        <div id={"winepaper_section"} className={style.winepaper_block}>
                            <div className={style.text_block}>
                                <div className={style.wine_header}>
                                    <img src={wine} alt="wine" />
                                    <h1>WINEPAPER</h1>
                                </div>
                                <p>
                                    "There isn't so many better pleasures than using Dionysus dApps, while having a
                                    glass of good wine."
                                </p>

                                <p>
                                    Honest Access* to our services is permission-less - we don't ban any wallet.
                                    Non-custodian services with on-chain evaluation and oracles - all those are our
                                    key
                                    ingredinces in dApp delivery.

                                </p>
                                <p>
                                    We've reinvented a micro-market for our users. Get our Winepaper for more
                                    information.
                                </p>
                                <PrimaryButton onClick={() => handleDownload()} backgroundColor={"white"}>Download</PrimaryButton>
                            </div>
                            <div className={style.photo}>
                                <img src={winepapperBack} alt={"background"} />
                            </div>
                        </div>
                    </div>

                </div>

                <div id="connect_section" className={style.connect_section}>
                    <div className={style.connect_section_text}>
                        <h1>SIGN UP FOR NEWSLETTER</h1>
                    </div>
                    <div className={style.input_block}>
                        <input type="email" placeholder="Your Email" />
                        <PrimaryButton isInputButton>SIGN UP</PrimaryButton>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MainPage;
