import React from 'react';
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

import style from "../styles/TermOfUse.module.scss";


const TermOfUse = () => {
    return (
        <>
            <div className={style.termOfUse__wrapper}>
                <Navbar/>
                <div className={style.contactUs__content}>
                    <h1><span style={{color: '#910420'}}>TERMS OF</span> USE</h1>
                    <div className={style.text_block}>
                        <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR SITE.

                        Dionysus and all its related entities, ("Dionysus") provides this website, Dionysus.com and its media channels (together with its contents, all sub-websites and all sub-media channels, the or this "Site") for your informational purposes only. Please read these terms of use ("Terms of Use") carefully before using the Site. By accessing the Site and using its content, you acknowledge and agree that you have read and understood these Terms of Use and agree to be bound by them. Dionysus reserves the right to modify these Terms of Use at any time, therefore we suggest you review these terms periodically for any change. If you do not agree to these Terms of Use, please do not use this Site.

                                                ‍
                        <h2>Copyright</h2>
                        We or our licensors own the copyright subsisting in the Site and its contents. You may download and temporarily store one or more of the pages of this Site for the purposes of viewing them and you may print any page from this Site for your personal and domestic use only. Any other storage, copying, transmission or distribution of the content of this Site is prohibited without our consent.
                        You warrant that any information or material that you upload to this Site is owned by you or used by you under a valid license, and that such information or material will not infringe the rights of any third party, and you shall indemnify us for any loss arising from any breach of this warranty.

                        ‍

                        <h2>Trademarks</h2>
                        The marks 'Dionysus' and (and any logos used by Dionysus in relation to those marks) are trademarks of Dionysus, and nothing in these Terms of Use gives you the right to use those trademarks without our prior written approval.

                        ‍

                        <h2>Personal Data</h2>
                        You confirm that all information and details provided by you to us via the Site are true, accurate and up to date in all respects and at all times. Your access or use of the Site is also subject to our Privacy Policy.

                        ‍

                        <h2>Information Provided by You</h2>
                        No information or materials that you provide via this Site will be considered or treated as confidential or proprietary. By providing any such information or materials to Dionysus, you grant to Dionysus an unrestricted, irrevocable, worldwide, royalty-free, non-exclusive license to use, reproduce, modify, publicly display, publicly perform, transmit and distribute such information and materials, and you further agree that Dionysus is free to use any ideas, concepts or know-how that you or individuals acting on your behalf provide to Dionysus. You agree not to use the Site to distribute, email or post any advertising, promotion or solicitation without our prior approval. You agree not to use the Site to upload, display or distribute any materials that are defamatory, threatening, obscene, harassing, or otherwise unlawful, or that incorporate the copyrighted, confidential or proprietary material of any other person or entity.

                        ‍

                        <h2>Liability</h2>
                        Any content of this Site that is provided by Dionysus or its licensors is provided for general information only and we do not accept any responsibility for its use. We provide the Site on an "as is" and "as available" basis and, to the maximum extent permitted by applicable laws, we exclude all representations, warranties or guarantees, whether express or implied, in relation to the Site.

                        We exclude all liability which might arise from your use or reliance on the content of the Site, save to the extent such liability arises from our fraud or fraudulent misrepresentation or from any death or personal injury that arises due to our negligence.

                        While we use reasonable efforts to ensure that the Site is free from viruses and other malicious or harmful content, we cannot guarantee that your use of this Site (including any content on it or any website accessible from it) shall not cause damage to your computer or other device. Except where required by applicable law, we shall not be liable to you for any loss or damage you suffer as a result of viruses or other malicious or harmful content that you access from or via the Site.

                        We do not guarantee that the Site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of the Site for any reason.



                        <h2>Third Party Websites</h2>
                        Where this Site contains links to other websites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources.



                        <h2>User-Generated Content</h2>
                        This Site may include information and materials uploaded by other users of the Site, including to bulletin boards, chat rooms and forums. This information and these materials have not been verified or approved by us. The views expressed by other users on the Site do not represent our views or values.



                        <h2>Linking to this Site</h2>
                        You may link to this Site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.


                        <h2>Indemnity</h2>
                        You agree to defend, indemnify and hold harmless Dionysus, our affiliates from time to time, and our and their respective directors, officers, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, and expenses (including attorney's fees on a full indemnity basis) arising from or relating to: (a) your use of and access to Site; (b) your violation of any provision of these Terms of Use; or (c) your violation of any third party right, including any copyright, property, or privacy right. This defence and indemnification obligation will survive the termination of these Terms of Use and your use of the Site.



                        <h2>Security</h2>
                        You should be aware that the Internet, being an open network, is not secure. If you choose to send any electronic communication to us by means of this Site, you do so at your own risk. We cannot guarantee that such communications will not be intercepted or that they will reach the intended recipient safely.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default TermOfUse;
